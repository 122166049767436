import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-no-cookies",
  templateUrl: "./no-cookies.component.html",
  styleUrl: "./no-cookies.component.scss",
})
export class NoCookiesComponent implements OnInit, OnDestroy {
  private noCookieInterval!: ReturnType<typeof setInterval>;

  public ngOnInit(): void {
    this.noCookieInterval = setInterval(() => {
      if (navigator.cookieEnabled) {
        this.forcedRedirectToLogin();
      }
    }, 1000);
  }

  public ngOnDestroy(): void {
    clearInterval(this.noCookieInterval);
  }

  private forcedRedirectToLogin(): void {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }
}
