<footer>
  <div>
    <img
      class="logo"
      src="../../../../assets/images/as_socialpals_logotype.svg"
      alt="socialPALS"
    />
    <a href="mailto:info@socialpals.de">info&#64;socialpals.de</a>
    <a [routerLink]="['/terms-and-conditions']" target="_blank">
      {{ "shared.termsAndConditions" | translate }}
    </a>
    <a href="tel:+4980249016281">+49 (0)8024 90162 81</a>
    <a [routerLink]="['/privacy']" target="_blank">{{
      "shared.privacyPolicy" | translate
    }}</a>
  </div>
</footer>
