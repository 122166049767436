import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NoCookiesComponent } from "./no-cookies.component";

export const routes: Routes = [{ path: "", component: NoCookiesComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NoCookiesRoutingModule {}
