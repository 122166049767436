import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NoCookiesFooterComponent } from "./footer/no-cookies-footer.component";
import { NoCookiesHeaderComponent } from "./header/no-cookies-header.component";
import { NoCookiesRoutingModule } from "./no-cookies-routing.module";
import { NoCookiesComponent } from "./no-cookies.component";

@NgModule({
  declarations: [
    NoCookiesComponent,
    NoCookiesFooterComponent,
    NoCookiesHeaderComponent,
  ],
  imports: [NoCookiesRoutingModule, TranslateModule, CommonModule],
  exports: [
    NoCookiesComponent,
    NoCookiesFooterComponent,
    NoCookiesHeaderComponent,
  ],
})
export class NoCookiesModule {}
