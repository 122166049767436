<app-no-cookies-header></app-no-cookies-header>

<article>
  <section>
    <img
      height="247"
      src="/assets/images/reload-page-man.png"
      alt="{{ 'noCookiesErrorPage.imageAlt' | translate }}"
    />
    <div>
      <h1>{{ "noCookiesErrorPage.title" | translate }}</h1>
      {{ "noCookiesErrorPage.message" | translate }}
    </div>
  </section>
</article>

<app-no-cookies-footer></app-no-cookies-footer>
