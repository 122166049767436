<header>
  <div>
    <section>
      <img
        src="../../../../assets/images/as_socialpals_logotype.svg"
        alt="socialPALS"
      />
    </section>
    <section class="right-section">
      <i></i>
      <a href="tel:+4980249016281">+49 (0)8024 90162 81</a>
    </section>
  </div>
</header>
